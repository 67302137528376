export const SITEID = 4; 
export const SITENAME = "Zee Telugu";
const ENV = 'prod';

let APIBASE = "http://localhost:3000/";
let SITEKEY = '6LfVNI0iAAAAANj_idiuPmdQzRRqO5dMT8mi4_Yb'; //localhost
if(ENV == 'prod'){
    SITEKEY = '6Lfp70AUAAAAAJMFH3fQ_11tz1fVzfckgskNqXZx';
    APIBASE = "https://b2b-channels.zee5.com/";
} else if (ENV == 'stage') {
    SITEKEY = '6LfpspoiAAAAANSPyD8jKct7oAb-CmQF5-Y6YB_d';
    APIBASE = "https://b2b-apidev.zee5.be/";
}

export const API_ENDPOINT = APIBASE + "settings/" + SITEID;
export const HOME_ENDPOINT = APIBASE + "home/" + SITEID;
export const PAGES_ENDPOINT = APIBASE + SITEID + "/pages/";
export const FEEDBACK_FORM_URL = APIBASE + 'feedback/';
export const STATES_URL = APIBASE + 'getStates/';

var SITE_CHANNEL = 'zeetelugu';
var SITE_CHANNEL1 = 'Zee Telugu';
var FEEDBACK_FORM_ID = '29';

switch(SITEID) {
    case 4:
        SITE_CHANNEL = 'zeetelugu';
        SITE_CHANNEL1 = 'Zee Telugu';
        FEEDBACK_FORM_ID = '29';
    break;
    case 5:
        SITE_CHANNEL = 'ZeeTamil';
        FEEDBACK_FORM_ID = '20963';
    break;
}



export const SITE_CAPTCHA_KEY = SITEKEY;
export { SITE_CHANNEL, SITE_CHANNEL1, FEEDBACK_FORM_ID };